import React from 'react'
import { motion } from 'framer-motion'

function ElectronicsDevelopment(props) {
  return (
    <svg
      viewBox="0 0 2000 1000"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={245.756}
          id="ProjectEngineering_GoalSetting_svg__a"
          x1={367.842}
          y2={245.756}
          x2={771.179}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.864 27.848) scale(1.54719)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={245.756}
          id="ProjectEngineering_GoalSetting_svg__b"
          x1={552.851}
          y2={245.756}
          x2={586.17}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.864 27.848) scale(1.54719)"
        >
          <stop stopColor="#fbc5c5" offset={0} />
          <stop stopColor="#fff" offset={1} />
        </linearGradient>
        <linearGradient
          y1={293.981}
          id="ProjectEngineering_GoalSetting_svg__c"
          x1={595.815}
          y2={293.981}
          x2={629.134}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.864 27.848) scale(1.54719)"
        >
          <stop stopColor="#35b775" offset={0.003} />
          <stop stopColor="#0b8044" offset={0.998} />
        </linearGradient>
        <linearGradient
          y1={286.967}
          id="ProjectEngineering_GoalSetting_svg__d"
          x1={479.198}
          y2={286.967}
          x2={512.517}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.864 27.848) scale(1.54719)"
        >
          <stop stopOpacity={0.765} stopColor="#f545ae" offset={0} />
          <stop stopOpacity={0.734} stopColor="#f5164c" offset={0.983} />
        </linearGradient>
        <linearGradient
          y1={211.786}
          id="ProjectEngineering_GoalSetting_svg__e"
          x1={73.991}
          y2={211.786}
          x2={126.239}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(234.864 27.848) scale(1.54719)"
        >
          <stop stopColor="#fbc5c5" offset={0.005} />
          <stop stopColor="#e3abab" offset={0.995} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M768.549 27.845l996.591 590.539-29.98 50.58L738.577 78.425l29.972-50.58zM551.943 77.355l996.587 590.539-29.97 50.58-996.589-590.539 29.972-50.58zM360.092 131.506l996.588 590.539-29.97 50.58-996.59-590.539 29.972-50.58z"
          fill="#dfdede"
        />
        <motion.g
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ['20%', '20%', '50%', '50%', '20%'],
          }}
          transition={{ duration: 3, loop: Infinity, repeatDelay: 5 }}
        >
          <path
            d="M803.986 408.079c0-172.324 139.696-312.02 312.024-312.02 172.32 0 312.02 139.696 312.02 312.02 0 172.324-139.7 312.02-312.02 312.02-172.328 0-312.024-139.696-312.024-312.02z"
            fill="url(#ProjectEngineering_GoalSetting_svg__a)"
          />
        </motion.g>
        <path
          d="M1116.01 465.057c-30.72 0-55.62-24.902-55.62-55.621s24.9-55.621 55.62-55.621c30.71 0 55.62 24.902 55.62 55.621-.04 30.704-24.92 55.586-55.62 55.621zm0-108.529c-29.22 0-52.91 23.688-52.91 52.908 0 29.22 23.69 52.908 52.91 52.908 29.22 0 52.9-23.688 52.9-52.908-.03-29.207-23.7-52.875-52.9-52.908z"
          fill="#3f3d56"
        />
        <path
          d="M1116.01 515.251c-58.44 0-105.82-47.375-105.82-105.815s47.38-105.815 105.82-105.815c58.44 0 105.81 47.375 105.81 105.815-.06 58.413-47.4 105.75-105.81 105.815zm0-208.917c-56.95 0-103.11 46.16-103.11 103.102 0 56.942 46.16 103.102 103.11 103.102 56.94 0 103.1-46.16 103.1-103.102-.07-56.915-46.19-103.038-103.1-103.102z"
          fill="#fff"
        />
        <path
          d="M1116.01 558.663c-82.42 0-149.231-66.811-149.231-149.227s66.811-149.227 149.231-149.227c82.41 0 149.22 66.811 149.22 149.227-.09 82.377-66.85 149.134-149.22 149.227zm0-295.741c-80.92 0-146.518 65.597-146.518 146.514 0 80.917 65.598 146.514 146.518 146.514 80.91 0 146.51-65.597 146.51-146.514-.09-80.879-65.63-146.422-146.51-146.514z"
          fill="#fff"
        />
        <path
          d="M1116.01 615.64c-113.71 0-206.208-92.502-206.208-206.204 0-113.701 92.498-206.205 206.208-206.205 113.7 0 206.2 92.504 206.2 206.205 0 113.702-92.5 206.204-206.2 206.204zm0-409.695c-112.21 0-203.495 91.285-203.495 203.491 0 112.205 91.285 203.491 203.495 203.491 112.2 0 203.49-91.286 203.49-203.491 0-112.206-91.29-203.491-203.49-203.491z"
          fill="#fff"
        />
        <path
          d="M1116.01 663.122c-139.887 0-253.69-113.803-253.69-253.686S976.123 155.75 1116.01 155.75c139.88 0 253.68 113.803 253.68 253.686s-113.8 253.686-253.68 253.686zm0-504.659c-138.391 0-250.976 112.586-250.976 250.973s112.585 250.973 250.976 250.973c138.38 0 250.97-112.586 250.97-250.973s-112.59-250.973-250.97-250.973z"
          fill="#e4e2e2"
        />
        <motion.g
          animate={{
            y: [0, -20, 20, 0, -40, 40, 0],
            x: [0, -100, 50, -800, 0, 80, 0],
          }}
          transition={{ duration: 4, loop: Infinity, repeatDelay: 1 }}
        >
          <path
            d="M1090.23 408.079c0-14.235 11.54-25.775 25.78-25.775 14.23 0 25.77 11.54 25.77 25.775 0 14.236-11.54 25.776-25.77 25.776-14.24 0-25.78-11.54-25.78-25.776z"
            fill="url(#ProjectEngineering_GoalSetting_svg__b)"
          />
        </motion.g>
        <motion.g
          animate={{
            y: [0, 20, -20, 0, 40, -40, 0],
            x: [0, 100, -50, 800, 0, -80, 0],
          }}
          transition={{ duration: 4, loop: Infinity, repeatDelay: 1 }}
        >
          <path
            d="M1156.7 482.693c0-14.236 11.54-25.776 25.78-25.776 14.24 0 25.78 11.54 25.78 25.776 0 14.235-11.54 25.775-25.78 25.775-14.24 0-25.78-11.54-25.78-25.775z"
            fill="url(#ProjectEngineering_GoalSetting_svg__c)"
          />
        </motion.g>
        <motion.g
          animate={{
            y: [0, 20, -20, 0, 40, -40, 0],
            x: [0, 50, -500, 0, 800, -80, 0],
          }}
          transition={{ duration: 4, loop: Infinity, repeatDelay: 1 }}
        >
          <path
            d="M976.275 471.84c0-14.235 11.541-25.776 25.775-25.776 14.24 0 25.78 11.541 25.78 25.776s-11.54 25.776-25.78 25.776c-14.234 0-25.775-11.541-25.775-25.776z"
            fill="url(#ProjectEngineering_GoalSetting_svg__d)"
          />
        </motion.g>
        <path
          d="M1103.8 408.079c0-6.743 5.46-12.209 12.21-12.209 6.74 0 12.21 5.466 12.21 12.209 0 6.743-5.47 12.21-12.21 12.21-6.75 0-12.21-5.467-12.21-12.21zM989.842 471.84c0-6.743 5.466-12.209 12.208-12.209 6.74 0 12.21 5.466 12.21 12.209 0 6.743-5.47 12.21-12.21 12.21-6.742 0-12.208-5.467-12.208-12.21zM1170.27 484.049c0-6.743 5.47-12.209 12.21-12.209 6.74 0 12.21 5.466 12.21 12.209 0 6.744-5.47 12.21-12.21 12.21-6.74 0-12.21-5.466-12.21-12.21z"
          fill="#2f2e41"
        />
        <path
          d="M1673.57 271.062c0-6.743 5.47-12.21 12.21-12.21 6.74 0 12.21 5.467 12.21 12.21 0 6.743-5.47 12.209-12.21 12.209-6.74 0-12.21-5.466-12.21-12.209z"
          fill="#35b775"
        />
        <path
          d="M1116.68 408.757h-2.71v-139.73h538.58v2.714h-535.87v137.016z"
          fill="#2f2e41"
        />
        <motion.g
          animate={{
            y: [0, 20, -20, 0],
          }}
          transition={{ duration: 1, loop: Infinity }}
        >
          <path
            d="M1685.78 306.334c-19.48 0-35.27-15.792-35.27-35.272 0-19.48 15.79-35.272 35.27-35.272 19.48 0 35.27 15.792 35.27 35.272-.02 19.471-15.8 35.25-35.27 35.272zm0-67.831c-17.98 0-32.56 14.577-32.56 32.559s14.58 32.558 32.56 32.558c17.98 0 32.56-14.576 32.56-32.558-.02-17.973-14.59-32.538-32.56-32.559z"
            fill="#2f2e41"
          />
        </motion.g>
        <path
          d="M1592.18 622.424c0-6.744 5.46-12.21 12.21-12.21 6.74 0 12.2 5.466 12.2 12.21 0 6.743-5.46 12.209-12.2 12.209-6.75 0-12.21-5.466-12.21-12.209z"
          fill="#35b775"
        />
        <path
          d="M1604.39 657.695c-19.48 0-35.28-15.791-35.28-35.271 0-19.481 15.8-35.272 35.28-35.272s35.27 15.791 35.27 35.272c-.02 19.471-15.8 35.25-35.27 35.271zm0-67.83c-17.99 0-32.56 14.577-32.56 32.558 0 17.982 14.57 32.559 32.56 32.559 17.98 0 32.55-14.577 32.55-32.559-.02-17.973-14.58-32.538-32.55-32.558z"
          fill="#2f2e41"
        />
        <path
          d="M611.348 328.039c0-6.743 5.466-12.209 12.209-12.209 6.743 0 12.21 5.466 12.21 12.209 0 6.744-5.467 12.21-12.21 12.21-6.743 0-12.209-5.466-12.209-12.21z"
          fill="#35b775"
        />
        <motion.g
          animate={{
            y: [0, 20, -20, 0],
          }}
          transition={{ duration: 1, loop: Infinity }}
        >
          <path
            d="M623.557 363.311c-19.48 0-35.272-15.791-35.272-35.272 0-19.48 15.792-35.271 35.272-35.271 19.48 0 35.272 15.791 35.272 35.271-.022 19.471-15.801 35.25-35.272 35.272zm0-67.83c-17.981 0-32.558 14.577-32.558 32.558 0 17.982 14.577 32.559 32.558 32.559 17.982 0 32.559-14.577 32.559-32.559-.021-17.973-14.586-32.538-32.559-32.558z"
            fill="#2f2e41"
          />
        </motion.g>
        <path
          d="M1003.41 471.841h-2.72v-137.04l-343.239-5.406.042-2.712 345.917 5.448v139.71zM1570.47 623.78h-389.35V484.05h2.72v137.017h386.63v2.713zM1611.17 334.823h149.23v9.496h-149.23v-9.496zM1611.17 361.955h149.23v9.496h-149.23v-9.496zM1611.17 389.087h149.23v9.496h-149.23v-9.496zM1529.77 686.184H1679v9.496h-149.23v-9.496zM1529.77 713.316H1679v9.497h-149.23v-9.497zM1529.77 740.449H1679v9.496h-149.23v-9.496zM548.944 393.157h149.227v9.496H548.944v-9.496zM548.944 420.289h149.227v9.496H548.944v-9.496zM548.944 447.421h149.227v9.496H548.944v-9.496z"
          fill="#2f2e41"
        />
        <path
          d="M831.118 918.175c0-5.245 133.015-9.497 297.102-9.497 164.08 0 297.09 4.252 297.09 9.497 0 5.244-133.01 9.496-297.09 9.496-164.087 0-297.102-4.252-297.102-9.496z"
          fill="#dfdede"
        />
        <path
          d="M234.864 969.061H1237.44v3.094H234.864v-3.094z"
          fill="#2f2e41"
        />
        <path
          d="M349.342 305.084s14.201 40.418 5.462 46.636c-8.739 6.219 29.495 57.001 29.495 57.001l45.88-12.437-25.125-42.491s-3.277-42.491-3.277-48.709c0-6.218-52.435 0-52.435 0z"
          fill="#ffb8b8"
        />
        <path
          d="M349.342 305.084s14.201 40.418 5.462 46.636c-8.739 6.219 29.495 57.001 29.495 57.001l45.88-12.437-25.125-42.491s-3.277-42.491-3.277-48.709c0-6.218-52.435 0-52.435 0z"
          opacity={0.98}
          fill="url(#ProjectEngineering_GoalSetting_svg__e)"
        />
        <path
          d="M325.506 593.194s-4.146 51.819 0 78.764c4.145 26.946 10.363 101.564 10.363 101.564s0 140.947 22.8 143.019c22.801 2.073 39.382 4.146 41.455-4.145 2.073-8.291-10.363-12.437-4.145-16.582 6.218-4.146 8.291-18.655 0-35.237s0-184.473 0-184.473l39.382 105.709s4.145 87.055 8.291 99.492c4.145 12.436-4.146 35.236 10.363 37.309 14.51 2.073 31.091-10.364 39.382-14.509 8.291-4.146-12.436-4.146-8.291-6.218 4.146-2.073 16.582-8.291 12.437-10.364-4.146-2.073-8.291-101.564-8.291-101.564s-11.4-212.456-25.909-220.747c-14.51-8.291-23.837 5.99-23.837 5.99l-114 21.992z"
          fill="#2f2e41"
        />
        <path
          d="M362.815 908.25v16.582s-18.655 45.075 0 45.075 33.164 4.671 33.164-1.547v-55.964l-33.164-4.146zM485.107 908.221v16.582s18.654 45.075 0 45.075c-18.655 0-33.164 4.67-33.164-1.548v-55.964l33.164-4.145z"
          fill="#2f2e41"
        />
        <path
          d="M341.051 297.829c0-20.605 16.704-37.309 37.309-37.309 20.606 0 37.31 16.704 37.31 37.309 0 20.606-16.704 37.31-37.31 37.31-20.605 0-37.309-16.704-37.309-37.31z"
          fill="#fbc5c5"
        />
        <path
          d="M366.96 367.266s10.364 31.091 26.946 24.873l16.582-6.218 29.018 198.982s-22.8 33.164-55.964 12.437c-33.163-20.728-16.582-230.074-16.582-230.074z"
          fill="#fff"
        />
        <path
          d="M395.979 361.048l9.327-19.691s54.928 25.909 63.219 34.2c8.291 8.291 8.291 20.727 8.291 20.727l-14.51 51.819s4.146 114 4.146 118.146c0 4.145 14.509 26.945 4.145 18.654-10.363-8.291-12.436-16.582-20.727-4.145-8.291 12.436-26.946 26.945-26.946 26.945l-26.945-246.655z"
          fill="#3f3d56"
        />
        <path
          d="M468.525 510.285l-6.219 58.036s-37.309 33.164-29.018 35.237c8.291 2.073 12.436-6.218 12.436-6.218s14.51 14.509 22.801 6.218c8.29-8.291 29.018-87.055 29.018-87.055l-29.018-6.218z"
          fill="#fbc5c5"
        />
        <path
          d="M379.155 245.101c-8.271-.296-17.122-.442-24.096 4.015a35.297 35.297 0 00-8.327 8.152c-6.792 8.586-12.661 19.389-10.138 30.042l2.931-1.143a19.205 19.205 0 01-1.851 8.221c.412-1.199 1.794 1.954 0-3.228l-3.228 10.59a9.97 9.97 0 0112.714 7.587c.369-12.3 1.645-26.404 11.623-33.606 5.031-3.632 11.399-4.741 17.526-5.725 5.653-.908 11.578-1.775 16.992 0 5.414 1.774 10.024 7.397 8.796 12.989 2.497-.86 5.289 6.522 3.215 0a16.884 16.884 0 011.335 7.728c2.661 1.88 5.689-1.854 6.774-4.926 2.545-7.213 4.808-14.89 3.437-22.415-1.372-7.524-7.503-14.715-15.152-14.74a5.312 5.312 0 001.382-3.739l-6.304-.533a6.96 6.96 0 004.163-2.195c2.201 11.267-17.699 3.072-21.792 2.926z"
          fill="#2f2e41"
        />
        <path
          d="M377.324 354.83s-16.873-16.535-22.946-15.523c-6.072 1.013-14.363 15.523-14.363 15.523s-49.746 16.581-47.673 33.163c2.073 16.582 24.873 97.419 24.873 97.419s18.654 97.419 2.072 107.782c-16.581 10.364 78.764 37.31 80.837 24.873 2.073-12.436 6.218-136.801 0-155.455-6.218-18.655-22.8-107.782-22.8-107.782z"
          fill="#3f3d56"
        />
        <motion.g
          animate={{
            rotate: [0, 10, -10, 0],
          }}
          transition={{ duration: 2, loop: Infinity, repeatDelay: 1 }}
        >
          <path
            d="M362.624 491.291h25.255a4.992 4.992 0 014.991 4.991v52.642a4.992 4.992 0 01-4.991 4.991h-25.255a4.992 4.992 0 01-4.991-4.991v-52.642a4.992 4.992 0 014.991-4.991z"
            fill="#fff"
          />
        </motion.g>
        <path
          d="M310.997 528.939l29.018 12.437s55.964 8.291 55.964-14.509-55.964-10.364-55.964-10.364l-18.656-5.965-10.362 18.401z"
          fill="#fbc5c5"
        />
        <path
          d="M449.87 387.993h25.629s17.898 78.764 19.971 87.055c2.073 8.291 6.218 47.673 4.146 47.673-2.073 0-43.528-8.291-43.528-2.072l-6.218-132.656zM317.215 375.557l-24.873 6.218-18.655 109.855s-6.218 29.019 4.146 31.091c10.363 2.073 39.382 18.655 39.382 18.655s4.145-31.091 12.436-31.091l-20.727-16.582 12.436-72.546-4.145-45.6zM1022.38 908.72h-40.222v-40.227h40.222v40.227zm-37.128-3.094h34.038v-34.039h-34.038v34.039z"
          fill="#3f3d56"
        />
        <path
          d="M1031.67 893.248c0-8.545 6.92-15.472 15.47-15.472 8.54 0 15.47 6.927 15.47 15.472s-6.93 15.472-15.47 15.472c-8.55 0-15.47-6.927-15.47-15.472z"
          fill="#35b775"
        />
      </g>
    </svg>
  )
}

export default ElectronicsDevelopment
