import React from 'react'
import Img from 'gatsby-image'
import Paragraph from './paragraph'
import SlideInTop from '../utility/slideInTopAnimation'

const TextImageRight2 = props => (
  <div className="section">
    <div className="container">
      <div className="columns is-multiline">
        <div
          className="column is-8 is-offset-2 has-text-centered"
          style={{ marginBottom: '-150px', marginTop: '-150px' }}
        >
          {props.fixed && (
            <div className="column">
              <SlideInTop>
                <Img fixed={props.fixed} style={props.style} alt={props.alt} />
              </SlideInTop>
            </div>
          )}
          {props.animatedIllustration && (
            <div className="column">
              <SlideInTop>{props.animatedIllustration}</SlideInTop>
            </div>
          )}
        </div>
        <div className="column is-6">
          <div
            className="content whiteblockquote"
            style={{ backgroundColor: '#f9f9f9' }}
          >
            <Paragraph title={props.titleOne} text={props.textOne} />
          </div>
        </div>
        <div className="column is-6">
          <div
            className="content is-gradient"
            style={{ borderRadius: '10px', padding: '4em' }}
          >
            <Paragraph title={props.titleTwo} text={props.textTwo} />
          </div>
        </div>
        <div className="column is-6">
          {props.titleThree && (
            <div
              className="content whiteblockquote"
              style={{ border: '1px solid #35b775' }}
            >
              <Paragraph title={props.titleThree} text={props.textThree} />
            </div>
          )}
        </div>
        <div className="column is-6">
          {props.titleFour && (
            <div
              className="content whiteblockquote"
              style={{ border: '1px solid #35b775' }}
            >
              <Paragraph title={props.titleFour} text={props.textFour} />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default TextImageRight2
