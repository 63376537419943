import React from 'react'
import ProductHeader from '../../components/header/productHeader'
import TextImageRight2 from '../../components/common/textWithRightImage2'
import { graphql } from 'gatsby'
import Link from '../../utilities/link'
import CommonFooter from '../../components/footer/commonFooter'
import HighlightsSeriesWithImage from '../../components/common/highlightSeriesWithImage'
import TextBlocks2 from '../../components/common/textBlocks2'
import Card from '../../components/common/card'
import StylishLinks from '../../components/common/stylishLinks'
import ElectronicsDevelopment from '../../illustrations/ProjectEngineeringGoalSetting'
import Seo from '../../components/seo/seo'
import SvgKitHera from '../../illustrations/Products/Hera/KitHera'
import SvgHeraLaundry from '../../illustrations/Products/Hera/HeraLaundry'
import '../../layouts/custom.scss'
import Img from 'gatsby-image'
import SlideInTop from '../../components/utility/slideInTopAnimation'
import i18n from '../../i18n'
import LaundryNews from '../../components/forms/laundryNewsForm'
import { useTranslation } from 'react-i18next'
import ElegantHeader from '../../components/header/elegantHeader'
import { StaticImage } from "gatsby-plugin-image"

const HeraIT = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t('hera.seoTitle')}
        description={t('hera.seoDescription')}
        seoImage={data.hera.childImageSharp.resize.src}
      />
      <ElegantHeader
        title={t('hera.headerTitle')}
        subtitle={t('hera.headerSubtitle')}
        buttonTitle={t('hera.headerButtonText')}
        buttonLink="https://www.heralaundry.com/"
        buttonTitleTag={t('hera.headerButtonLinkTitleTag')}
        image={<StaticImage className="bg-white rounded-full" src="../../images/products/hera/HeraLaundry.png" width={400} alt="Hera Laundry - Electronic Control for Washing Machines" />}
      />
      {/* <ProductHeader
        isGradient=""
        headerTitle={t('hera.headerTitle')}
        headerSubtitle={t('hera.headerSubtitle')}
        animatedIllustration={<SvgHeraLaundry />}
        imageOpacity="0.8"
        buttonText={t('hera.headerButtonText')}
        buttonLink="https://www.heralaundry.com/"
        buttonLinkTitleTag={t('hera.headerButtonLinkTitleTag')}
      /> */}
      <HighlightsSeriesWithImage
        title={t('hera.hTitle')}
        mainOne={t('hera.hmainOne')}
        mainTwo={t('hera.hmainTwo')}
        mainThree={t('hera.hmainThree')}
        mainFour={t('hera.hmainFour')}
        mainFive={t('hera.hmainFive')}
        // fixed={data.heraIcon.childImageSharp.fixed}
        animatedIllustration={<SvgHeraLaundry />}
      />
      <div className="maingrad md:py-24">
        <div className="section">
          <SlideInTop>
            <Card 
              cardTitle={t('hera.tbintroTitle')}
              cardSubtitle={t('hera.tbintroQuote')}
            />
          </SlideInTop>
          <div className='mt-12 grid grid-cols-1 md:grid-cols-2 gap-8'>
            {edges
            .filter(({ node }) => {
              return node.frontmatter.lang === i18n.language.split('-')[0]
            })
            .map(({ node }) => {
              const { title, photo, translatedPath } = node.frontmatter
              return (
                  <Link to={`/${translatedPath}`}>
                    <div className="card hover:drop-shadow-lg">
                      <div className="card-image">
                        <figure className="image is-4by3">
                          <Img
                            fixed={photo.childImageSharp.fixed}
                            style={{
                              width: '100%',
                              height: '250px',
                              borderRadius: '10px 10px 0 0',
                            }}
                            //   imgStyle={{ height: '250px' }}
                          />
                        </figure>
                      </div>
                      <div className="card-content">
                        <p className="text-xl font-semibold">{title}</p>
                      </div>
                    </div>
                  </Link>
              )
            })}
          </div>
        </div>
      </div>
      <TextImageRight2
        titleOne={<>{t('hera.titleOne')}</>}
        textOne={<>{t('hera.textOne')}</>}
        titleTwo={<>{t('hera.titleTwo')}</>}
        textTwo={<>{t('hera.textTwo')}</>}
        animatedIllustration={<SvgKitHera />}
        // style={{ width: '560px' }}
      />
      <StylishLinks
        animatedIllustration={<ElectronicsDevelopment />}
        linkOneTitle={<>{t('hera.linkOneTitle')}</>}
        linkOneText={<>{t('hera.linkOneText')}</>}
        // linkOne={<>{t('hera.linkOne')}</>}
        // to1="/it/contatti"
        component={<LaundryNews />}
        buttonLabel={t('callToAction.subscribe')}
        buttonClass="is-primary"
        linkTwoTitle={<>{t('hera.linkTwoTitle')}</>}
        linkTwoText={<>{t('hera.linkTwoText')}</>}
        linkTwo={<>{t('hera.linkTwo')}</>}
        to2="https://www.heralaundry.com/"
      />
      <CommonFooter />
    </>
  )
}

export default HeraIT

export const heraQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { product: { eq: "hera laundry" } } }
    ) {
      edges {
        node {
          frontmatter {
            lang
            path
            translatedPath
            title
            photo {
              childImageSharp {
                fixed(width: 500) {
                  ...GatsbyImageSharpFixed
                }
                resize(width: 900, quality: 90) {
                  src
                }
              }
            }
          }
        }
      }
    }
    hera: file(
      relativePath: { eq: "hera/HeraLaundry_IndustrialLaundryEquipment.png" }
    ) {
      childImageSharp {
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    heraIcon: file(relativePath: { eq: "icons/HERA.png" }) {
      childImageSharp {
        fixed(width: 560) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
